import React, { useState, useRef, useEffect } from "react";
import "./index.css"; // Landing page specific styles
import "bootstrap-icons/font/bootstrap-icons.css"; // Ensure the correct path is used

function LandingPage() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    carPlate: "",
    idType: "",
    nric: "",
    mileage: "",
    remark: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const idTypeElement = document.getElementById("idType");
    const idTypeText = idTypeElement.options[idTypeElement.selectedIndex].text;

    try {
      // Update the URL to your local backend
      const response = await fetch(
        "https://artofcaars-ai-send-email-service-315076560532.asia-southeast1.run.app/api/send-email", // Change from Vercel URL to localhost
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            idType: idTypeText,
          }),
        }
      );

      const result = await response.json();
      if (result.success) {
        alert("Enquiry sent successfully!");
      } else {
        alert("Failed to send enquiry. Please try again.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("An error occurred while sending the enquiry.");
    }
  };

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([
    {
      from: "AI",
      text: `Welcome! How can I help you? Please ask questions related to Car Scrapping or Exporting.\n\n\nFor eg: Which email should I contact for your services?`,
    },
  ]);
  // Chat messages state
  const [inputMessage, setInputMessage] = useState(""); // State to store the user input message
  const [isLoading, setIsLoading] = useState(false); // Track the loading state for the AI response
  // Reference for the chat messages container
  const messagesEndRef = useRef(null);

  const toggleChatBox = () => {
    setIsChatOpen((prev) => !prev); // Toggle chat box visibility
  };

  const sendMessage = async () => {
    if (!inputMessage) return; // Don't send empty messages

    const userMessage = { from: "User", text: inputMessage };
    setChatMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputMessage(""); // Clear the input field

    // Reset textarea height after message is sent
    const textarea = document.querySelector(".chat-textarea");
    textarea.style.height = "40px"; // Revert to original height

    setIsLoading(true); // Set loading state to true when fetching starts

    try {
      const response = await fetch(
        "https://artofcaars-ai-groq-chat-service-315076560532.asia-southeast1.run.app/api/groq-chat",
        //"https://artofcaars-ai-groq-chat-service-315076560532.asia-southeast1.run.app/api/groq-chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message: inputMessage }),
        }
      );

      const data = await response.json();
      if (data.message) {
        const aiMessage = { from: "AI", text: data.message };
        setChatMessages((prevMessages) => [...prevMessages, aiMessage]);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false); // Set loading state to false once fetch completes
    }
  };

  // Scroll to the bottom whenever a new message is added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  return (
    <div className="LandingPage">
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-text">
          <h1>Scrap Your Car At The BEST Prices In 24 Hours</h1>
          <p>
            Receive only the most competitive quote from our dedicated staff
          </p>
        </div>
        <div className="form-container">
          <h2>Get Your Quote</h2>
          <form onSubmit={handleSubmit} className="form">
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="name">
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="phone">
                  Phone <span className="required">*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group">
                <label htmlFor="email">
                  Email <span className="required">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="carPlate">
                  Car Plate No. <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="carPlate"
                  name="carPlate"
                  value={formData.carPlate}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group">
                <label htmlFor="idType">
                  ID Type <span className="required">*</span>
                </label>
                <select
                  id="idType"
                  name="idType"
                  value={formData.idType}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select ID Type</option>
                  <option value="business">Business (e.g., 51234567M)</option>
                  <option value="club">
                    Club/Association/Organisation (e.g., T08PQ1234A)
                  </option>
                  <option value="company">Company (e.g., 198912345K)</option>
                  <option value="foreignCompany">
                    Foreign Company (e.g., T08FC1234A)
                  </option>
                  <option value="foreignId">
                    Foreign Identification Number (e.g., F/G/M1234567N)
                  </option>
                  <option value="passport">
                    Foreign Passport (e.g., 12345678)
                  </option>
                  <option value="government">
                    Government (e.g., T08GA1234A)
                  </option>
                  <option value="limitedLiability">
                    Limited Liability Partnership (e.g., T08LL1234A)
                  </option>
                  <option value="nric">Singapore NRIC (e.g., S1234567D)</option>
                  <option value="statutoryBoard">
                    Statutory Board (e.g., T08GB1234A)
                  </option>
                </select>
              </div>

              <div className="input-group">
                <label htmlFor="nric">
                  Last 4 Characters of NRIC <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="nric"
                  name="nric"
                  value={formData.nric}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group">
                <label htmlFor="mileage">
                  Mileage <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="mileage"
                  name="mileage"
                  value={formData.mileage}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="remark">Remark</label>
                <input
                  type="text"
                  id="remark"
                  name="remark"
                  value={formData.remark}
                  onChange={handleChange}
                />
              </div>
            </div>

            <button type="submit" className="submit-btn">
              Enquire
            </button>
          </form>
        </div>
      </div>

      {/* Info Section */}
      <div className="info-section">
        <h2>Selling Your Car Made Easier With Direct Cars</h2>
        <div className="info-items">
          <div className="info-item">
            <i className="bi bi-pencil-square info-icon"></i>
            <h3>Submit your details</h3>
            <p>
              Let us know the necessary information as required by LTA for us to
              retrieve your car details.
            </p>
          </div>
          <div className="info-item">
            <i className="bi bi-cash-coin info-icon"></i>
            <h3>Source For The Highest Quote</h3>
            <p>Getting The Best Price For You In Only 1 Day.</p>
          </div>
          <div className="info-item">
            <i className="bi bi-cart-check-fill info-icon"></i>
            <h3>Car Viewing</h3>
            <p>
              Best quote, a non-obligatory viewing and inspection of your car
              will be arranged by us at your convenience.
            </p>
          </div>
          <div className="info-item">
            <i className="bi bi-ui-checks info-icon"></i>
            <h3>Seal The Deal When Happy</h3>
            <p>
              Once satisfied with the price, confirm the transaction and get
              paid instantly! What an easy and fuss-free process!
            </p>
          </div>
        </div>
      </div>

      {/* WhatsApp Button */}
      <a
        href="https://wa.me/6588204204"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          className="whatsapp-icon"
        />
      </a>
      {/* Floating Chat Button */}
      <div className="chat-button" onClick={toggleChatBox}>
        <i className="bi bi-chat chat-icon"></i>
      </div>

      {/* Chat Box */}
      {isChatOpen && (
        <div className="chat-box">
          <div className="chat-box-header">Chat with AI</div>
          <div className="chat-box-messages">
            {chatMessages.map((msg, index) => (
              <div
                key={index}
                className={
                  msg.from === "AI"
                    ? "ai-message-wrapper"
                    : "user-message-wrapper"
                }
              >
                {/* AI Messages (Left-aligned with profile picture) */}
                {msg.from === "AI" && (
                  <>
                    <img
                      src="/jaya.jpg"
                      alt="AI Agent"
                      className="ai-profile-pic"
                    />
                    <p
                      className="ai-message"
                      dangerouslySetInnerHTML={{
                        __html: msg.text.replace(/\n/g, "<br/>"), // Replace \n with <br>
                      }}
                    />
                  </>
                )}

                {/* User Messages (Right-aligned with icon on the right) */}
                {msg.from !== "AI" && (
                  <>
                    <p className="user-message">{msg.text}</p>
                    <i className="bi bi-person-circle user-profile-icon"></i>
                  </>
                )}
              </div>
            ))}

            {/* Loading Animation & Subscript whenever fetching data */}
            {isLoading && (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p className="loading-subscript">
                  We are generating the best answer, please wait.
                </p>
              </div>
            )}

            {/* This empty div will always be scrolled into view when messages change */}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-box-input">
            <textarea
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onInput={(e) => {
                e.target.style.height = "auto"; // Reset height to auto
                e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scrollHeight
              }}
              rows="1" // Initial row
              className="chat-textarea"
            ></textarea>
            <button onClick={sendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LandingPage;
