import React from "react";
import "./index.css"; // Importing navbar-specific CSS
import "bootstrap-icons/font/bootstrap-icons.css"; // Ensure the correct path is used

function Navbar() {
  return (
    <div className="navbar-container">
      <div className="navbar-left">
        <img src="/logo.jpg" alt="Logo" className="logo" />
      </div>
      <div className="navbar-right">
        <div className="navbar-item">
          <i className="bi bi-envelope"></i>
          <div>
            <span className="navbar-label">EMAIL</span>
            <span>keshir5@gmail.com</span>
          </div>
        </div>
        <div className="navbar-item">
          <i className="bi bi-telephone"></i>
          <div>
            <span className="navbar-label">HOTLINE (24 HRS)</span>
            <span>+65 8820 4204</span>
          </div>
        </div>
        <div className="navbar-item">
          <i className="bi bi-clock"></i>
          <div>
            <span className="navbar-label">WORKING HOURS</span>
            <span>MON-FRI: 24HRS</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
